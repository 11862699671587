<!-- Navbar start -->
<header class="header-section fixed" (scroll)="onScroll()">
  <div class="overlay">
    <div class="container">
      <div class="row d-flex header-area">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" routerLink="/">
            <img
              src="assetsnew/images/logo-light.png"
              class="logo"
              alt="logo"
              loading="lazy"
            />
          </a>
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-content"
          >
            <i class="fas fa-bars"></i>
          </button>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbar-content"
          >
            <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/aboutus">About Us</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/contactus">Contact Us</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/faqs">Faqs</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/help">How it works</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a routerLink="/login" class="nav-link">Login</a>
              </li>
            </ul>
            <div
              class="right-area header-action d-flex align-items-center max-un"
            >
              <a routerLink="/signup" class="cmn-btn command_btn"
                >Sign Up
                <i class="icon-d-right-arrow-2"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- Navbar end -->

<!-- banner-section start -->
<section class="banner-section inner-banner privacy-content">
  <div class="overlay">
    <div class="banner-content d-flex align-items-center">
      <div class="container">
        <div class="row justify-content-start">
          <div class="col-lg-7 col-md-10">
            <div class="main-content">
              <h1 class="title3">Contact Us</h1>
              <div class="breadcrumb-area">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb d-flex align-items-center">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="help-center-section">
  <div class="overlay pb-120">
    <div
      class="container wow fadeInUp"
      style="visibility: visible; animation-name: fadeInUp"
    >
      <div class="row align-items-center justify-content-center">
        <div class="col-xl-12">
          <div class="tab-content mt-80">
            <div
              class="tab-pane fade active show"
              id="personal"
              role="tabpanel"
              aria-labelledby="personal-tab"
            >
              <div class="row justify-content-sm-start justify-content-center">
                <div class="col-xl-4 col-lg-4 col-sm-6 col-9">
                  <div class="single-box">
                    <div class="img-area mb-15">
                      <img src="assetsnew/images/fav.png" alt="icon" />
                    </div>
                    <h5>Location</h5>
                    <p>5/6 Grenfell Avenue, Taylor ACT 2913</p>
                    <p>7 UPTON CLOSE BANBURY<br/> OXFORDSHIRE OX161JD</p>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-sm-6 col-9">
                  <div class="single-box">
                    <div class="img-area mb-15">
                      <img src="assetsnew/images/fav.png" alt="icon" />
                    </div>
                    <h5>Telephone</h5>
                    <p class="m-0">+61255011844</p>
                    <p>Tayrex Exchange customer careline</p>
                    <p class="m-0">+61408392696 </p>
                    <p>Tayrex Exchange whatsapp chat only</p>
                    <p>+44 7312963774 (UK Support)</p>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-sm-6 col-9">
                  <div class="single-box">
                    <div class="img-area mb-15">
                      <img src="assetsnew/images/fav.png" alt="icon" />
                    </div>
                    <h5>Emails</h5>
                    <p class="m-0">For Payment enquiries:</p>
                    <p> Payment@tayrexchange.com </p>
                    <p class="m-0">For Registration enquiries:</p>
                    <p> Onboarding-compliance@tayrexchange.com </p>
                    <p class="m-0">For general enquiries:</p>
                    <p>support@tayrexchange.com </p>
                    <p class="m-0">For Technical enquires:</p>
                    <p>IT@tayrexchange.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-new-footer></app-new-footer>
